'use client';

import React from 'react';
import ReactDOM from 'react-dom';

import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import NextImage, { ImageProps as NextImageProps } from 'next/image';

import domains from '@/config/image-domains';

export type ImageProps = Omit<NextImageProps, 'src'> & {
  src: string | StaticImport | undefined | null;
  fallbackUrl?: string;
  fallback?: boolean;
};

const Image: React.FC<ImageProps> = (props) => {
  const {
    width,
    height,
    fallback = false,
    fallbackUrl = `/api/dummyimage?width=${width || 600}&height=${height || 600}`,
    src,
    alt,
    fill,
    priority,
    decoding,
    ...imageProps
  } = props;

  const imgSrc = fallback === false ? src || '' : src || fallbackUrl;

  if (
    typeof imgSrc === 'string' &&
    /^https?:\/\//.test(imgSrc) &&
    !domains.find((domain) =>
      imgSrc.startsWith(`${domain.protocol}://${domain.hostname}`),
    )
  ) {
    if (priority) {
      ReactDOM.preload(imgSrc, { as: 'image', fetchPriority: 'high' });
    }

    return (
      /* eslint-disable-next-line @next/next/no-img-element */
      <img
        src={imgSrc}
        alt={alt}
        width={width}
        height={height}
        style={
          fill
            ? {
                position: 'absolute',
                height: '100%',
                width: '100%',
                inset: 0,
                color: 'transparent',
              }
            : undefined
        }
        loading={!priority ? 'lazy' : undefined}
        decoding={decoding || 'async'}
        fetchPriority={priority ? 'high' : undefined}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...imageProps}
      />
    );
  }

  return (
    <NextImage
      src={imgSrc}
      width={width}
      height={height}
      alt={alt}
      fill={fill}
      priority={priority}
      decoding={decoding}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
    />
  );
};

export default Image;
