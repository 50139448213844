import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/image/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/loader/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/page-loader/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@mui/material/Backdrop/index.js");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@mui/material/CircularProgress/index.js");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/public/images/logo-small.png");
